@import '../abstracts/mixins';
@import '../base/typography';
@import '../abstracts/variables';

.loading_container{
  @include default-box-shadow;
  @include flex_col_jc_center_center;

  height:300px;
  background-color: #333333;
  color:$color-white;
}

.weather_stats_panel_container{
  animation-duration: 1s;
  animation-fill-mode: both;
  cursor: pointer;
  height:300px;

  .weather_stats_panel{
    width:100%;


    margin: 0 auto;

    transform-style: preserve-3d;
    transition: all 1.5s ease;

    .panel_contents{
      @include default-box-shadow;
      @include flex_col_jc_center_center;
      position:absolute;

      width:100%;
      height:300px;

      backface-visibility: hidden;

      text-align: center;

      .stats_title{
        @include flex_col_jc_center_center;
        @include lato-1rem-regular;

        color: rgb(255 255 255 / 80%);
        text-align: left;

        margin: 1rem;
        transform: translateZ(100px) scale(0.98);
      }

      .stats_panel_depth {
        @include flex_col_jc_center_center;

        width:90%;

        transform: translateZ(100px) scale(0.98);
        perspective: inherit;

        .weather_stats_container{
          @include flex_col_jc_center_center;

          .weather_stats{
            @include flex-row_jc_spaceb_center;

            .interp_icon_container{
              @include flex_col_jc_center_center;
              height:100px;
              margin:0 3rem;
            }

            .weather_stats_table{
              @include flex_col_jc_center_center;

              .weather_stats_info{


                .stats_info_table{
                  width:220px;

                  .cell_title {
                    @include raleway-1rem-regular;
                    color: white;
                    text-align: left;
                  }

                  .cell_value {
                    @include lato-3rem-regular;
                    color: white;
                    text-align: left;
                  }

                  .cell_caption {
                    @include raleway-1rem-regular;
                    color: $color-white;
                    text-align: left;
                  }

                  .temp_high {
                    @include lato-3rem-bold;
                    color: $color-error-light;
                    text-align: left;
                  }

                  .temp_low {
                    @include lato-3rem-bold;
                    color: $color-secondary-light;
                    text-align: left;
                  }

                  .date_high {
                    @include lato-2rem-regular;
                    color: $color-error-light;
                    text-align: left;
                  }

                  .date_low {
                    @include lato-2rem-regular;
                    color: $color-secondary-light;
                    text-align: left;
                  }

                  .trend_high {
                    @include raleway-1rem-regular;
                    color: $color-error-light;
                    text-align: left;
                  }

                  .trend_low {
                    @include raleway-1rem-regular;
                    color: $color-secondary-light;
                    text-align: left;
                  }
                }
              }
            }
          }

          .divider_line{
            height: 2px;
            margin: 0.3rem 0.2rem;
            border-radius: 1px;
            opacity: 0.8;
            background-color: white;
            width:100%;
          }

          .dist_stats_table{
            width:100%;
            text-align: left;

            .dist_caption{
              @include raleway-1rem-bold;
              color:$color-white;
              text-align: center;

              margin-top: 0.5rem;
            }

            .dist_table{
              width:inherit;

              .dist_cell_title{
                @include raleway-1rem-regular;
                color:$color-white;
                text-align: left;

              }
              .dist_cell_value{
                @include lato-1rem-regular;
                color:$color-white;
                text-align: right;
              }
            }
          }

        }

      }
    }
    .panel_temperature{
      transform-style: preserve-3d;
      background: #333333;
    }

    .panel_humidity{
      transform: rotateY(-180deg);
      transform-style: preserve-3d;
      background: #333333;
    }
  }
}

.rotated {
  transform: rotateY(180deg);
}