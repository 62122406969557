@import '../abstracts/mixins';
@import '../abstracts/variables';
@import '../base/typography';

.prd_main_container{
  @include flex_col_jc_center_center;

  .prd_main_title{
    @include raleway-1rem-bold;
    color: $color-white;
    text-align: center;

    margin-bottom:3rem;
  }

  .prd_content_wrapper{
    @include flex_col_jc_center_center;
    //
    width:100%;


    .mst_wrapper{
      width:90%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: minmax(400px, auto);

      row-gap: 24px;
      column-gap:24px;

      margin:12px 0;

      .mst_info_container{

        @include default-box-shadow;
        grid-column:1/2;
        grid-row: 1;
      }
      .mst_graph_container{

        @include default-box-shadow;
        grid-column:2/3;
        grid-row: 1;
      }
    }

    .price_widget_container{
      @include default-box-shadow;
      @include flex_row_jc_start_center;
      width:90%;
      overflow-x: scroll;
      padding:2rem;
      margin:1rem 0;
    }

    .price_graph_wrapper{
      width:90%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(300px, auto);

      row-gap: 24px;
      column-gap:24px;

      margin:12px 0;

      .price_graph_1_container{

        @include default-box-shadow;
        grid-column:1/2;
        grid-row: 1;
      }
      .price_graph_2_container{

        @include default-box-shadow;
        grid-column:2/3;
        grid-row: 1;
      }
      .price_graph_3_container{

        @include default-box-shadow;
        grid-column:3/4;
        grid-row: 1;
      }
      .price_graph_4_container{

        @include default-box-shadow;
        grid-column:1/2;
        grid-row: 2;
      }
      .price_graph_5_container{

        @include default-box-shadow;
        grid-column:2/3;
        grid-row: 2;
      }
      .price_graph_6_container{

        @include default-box-shadow;
        grid-column:3/4;
        grid-row: 2;
      }
      .price_graph_7_container{

        @include default-box-shadow;
        grid-column:1/2;
        grid-row: 3;
      }
      .price_graph_8_container{

        @include default-box-shadow;
        grid-column:2/3;
        grid-row: 3;
      }
      .price_graph_9_container{

        @include default-box-shadow;
        grid-column:3/4;
        grid-row: 3;
      }
      .price_graph_10_container{

        @include default-box-shadow;
        grid-column:2/3;
        grid-row: 4;
      }
    }
  }

.download_button_container{
  @include flex_row_jc_center_center;
  .download_button {
    @include flex_col_jc_center_center;
    @include raleway-1rem-bold;
    /* Rectangle 2 */
    width: fit-content;
    height: 40px;

    background: #D6EFE4;
    border-radius: 12px;
    color: #036666;

    padding: 0 1rem;
    margin: 2rem 1rem;

    cursor: pointer;
  }
}
}

@media (max-width: 1200px) {
  .prd_main_container{
    @include flex_col_jc_center_center;


    .prd_content_wrapper{
      @include flex_col_jc_center_center;
      //
      width:100%;


      .mst_wrapper{
        width:90%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(400px, auto);

        row-gap: 24px;
        column-gap:24px;

        margin:12px 0;

        .mst_info_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 1;
        }
        .mst_graph_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 2;
        }
      }

      .price_graph_wrapper{
        width:90%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(300px, auto);

        row-gap: 24px;
        column-gap:24px;

        margin:12px 0;

        .price_graph_1_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 1;
        }
        .price_graph_2_container{

          @include default-box-shadow;
          grid-column:2/3;
          grid-row: 1;
        }
        .price_graph_3_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 2;
        }
        .price_graph_4_container{

          @include default-box-shadow;
          grid-column:2/3;
          grid-row: 2;
        }
        .price_graph_5_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 3;
        }
        .price_graph_6_container{

          @include default-box-shadow;
          grid-column:2/3;
          grid-row: 3;
        }
        .price_graph_7_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 4;
        }
        .price_graph_8_container{

          @include default-box-shadow;
          grid-column:2/3;
          grid-row: 4;
        }
        .price_graph_9_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 5;
        }
        .price_graph_10_container{

          @include default-box-shadow;
          grid-column:2/3;
          grid-row: 5;
        }
      }
    }

  }
}

@media (max-width: 800px) {
  .prd_main_container{
    @include flex_col_jc_center_center;


    .prd_content_wrapper{
      @include flex_col_jc_center_center;
      //
      width:100%;


      .mst_wrapper{
        width:90%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(400px, auto);

        row-gap: 24px;
        column-gap:24px;

        margin:12px 0;

        .mst_info_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 1;
        }
        .mst_graph_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 2;
        }
      }

      .price_graph_wrapper{
        width:90%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(300px, auto);

        row-gap: 24px;
        column-gap:24px;

        margin:12px 0;

        .price_graph_1_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 1;
        }
        .price_graph_2_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 2;
        }
        .price_graph_3_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 3;
        }
        .price_graph_4_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 4;
        }
        .price_graph_5_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 5;
        }
        .price_graph_6_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 6;
        }
        .price_graph_7_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 7;
        }
        .price_graph_8_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 8;
        }
        .price_graph_9_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 9;
        }
        .price_graph_10_container{

          @include default-box-shadow;
          grid-column:1/2;
          grid-row: 10;
        }
      }
    }

  }
}