@import "../abstracts/mixins";
@import "../abstracts/breakpoints";
@import "../base/typography";

.main_container{
  @include absolute-center;
  @include flex_col_jc_center_center;

  .dashboard_title{
    color:white;
  }

  .map_container{
    @include flex_col_jc_center_center;
    height: 400px;
    width:800px;

    margin:3rem 0;

    .map_caption{
      color:white;
      margin:0.5rem 0;
    }
  }

  .proceed_button_container{
    @include flex_col_jc_center_center;
    @include raleway-1rem-bold;
    /* Rectangle 2 */
    width: 100px;
    height: 40px;

    background: #D6EFE4;
    border-radius: 12px;
    color: #036666;

    margin: 0 1rem;

    cursor: pointer;
  }
}


// Breakpoints

@media (max-width: 720px) {
  .main_container{
    @include absolute-center;
    @include flex_col_jc_center_center;

    .dashboard_title{
      color:white;
    }

    .map_container{
      @include flex_col_jc_center_center;
      height: 350px;
      width:350px;

      margin:2rem 0;
      z-index: -1100;

      .map_caption{
        color:white;
        margin:0.5rem 0;
      }
    }
  }
}

@media (max-width: 450px) {
  .main_container{
    @include absolute-center;
    @include flex_col_jc_center_center;

    .dashboard_title{
      @include raleway-2rem-bold;
      color:white;
    }

    .map_container{
      @include flex_col_jc_center_center;
      height: 300px;
      width:250px;

      margin:2rem 0;

      .map_caption{
        color:white;
        margin:0.5rem 0;
      }
    }
  }
}