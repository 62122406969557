@import '../abstracts/mixins';
@import '../abstracts/variables';
@import '../base/typography';

.MSTCanvas {
  @include flex_row_jc_center_center;
  position: relative;

  width: 100%;
  height: 400px;

  background: #333333;
}

div.vis-tooltip {
  height: fit-content;
  opacity: 0.8;
  background-color: $color-white;
}