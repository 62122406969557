@import '../abstracts/mixins';


/* ===== ===== ===== ===== First Breakpoint Style ===== ===== ===== ===== */


  .map_container{
    @include flex_col_jc_center_center;
    height: 100%;
    width:100%;

    z-index: -1100;

    .map_caption{
      color:white;
      margin:0.5rem 0;
    }
  }
