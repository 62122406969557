@import '../abstracts/mixins';
@import '../base/typography';
@import '../abstracts/variables';


/* ===== ===== ===== ===== First Breakpoint Style ===== ===== ===== ===== */

.agroklimatologi_main_container{
  @include flex_col_jc_center_center;

  .agroklimatologi_main_title{
    @include raleway-1rem-bold;
    color: $color-white;
    text-align: center;

    margin-bottom:3rem;
  }

  .download_button{
    @include flex_col_jc_center_center;
    @include raleway-1rem-bold;
    /* Rectangle 2 */
    width: fit-content;
    height: 40px;

    background: #D6EFE4;
    border-radius: 12px;
    color: #036666;

    padding: 0 1rem;
    margin: 2rem 1rem;

    cursor: pointer;
  }
}

.loading_container{
  @include default-box-shadow;
  @include flex_col_jc_center_center;

  height:300px;
  background-color: #333333;
  color:$color-white;
}

/* ----- ----- ----- ----- First Wrapper Styles ----- ----- ----- ----- */

.first_wrapper{
  display: grid;
  width:90%;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(300px, auto);

  row-gap: 24px;
  column-gap:24px;

  margin:12px 0;

  .map_wrapper{
    grid-row: 1 / 3;

    .map_container{
      @include flex_col_jc_center_center;
      height: 100%;
      width:100%;

      z-index: -1100;

      .map_caption{
        color:white;
        margin:0.5rem 0;
      }
    }
  }

  .widget_1_container{
    grid-column:2/3;
    grid-row: 1;
  }
  .widget_2_container{
    grid-column:2/3;
    grid-row: 2;
  }
  .widget_3_container{
    grid-column:3/4;
    grid-row: 1;
  }
  .widget_4_container{
    grid-column:3/4;
    grid-row: 2;
  }
}

.second_wrapper{
  display: grid;
  width:90%;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(300px, auto);

  row-gap: 24px;
  column-gap:24px;

  margin:12px 0;

  .graph_01_wrapper{
    grid-column: 1/2;
    grid-row: 1;
  }
  .graph_02_wrapper{
    grid-column: 2/3;
    grid-row: 1;
  }
  .graph_03_wrapper{
    grid-column: 3/4;
    grid-row: 1;
  }
  .graph_1_wrapper{
    grid-column: 1/2;
    grid-row: 2;
  }
  .graph_2_wrapper{
    grid-column: 2/3;
    grid-row: 2;
  }
  .graph_3_wrapper{
    grid-column: 3/4;
    grid-row: 2;
  }
  .graph_4_wrapper{
    grid-column: 1/2;
    grid-row: 3;
  }
  .graph_5_wrapper{
    grid-column: 2/3;
    grid-row: 3;
  }
  .graph_6_wrapper{
    grid-column: 3/4;
    grid-row: 3;
  }
  .graph_7_wrapper{
    grid-column: 2/3;
    grid-row: 4;
  }

}


/* ----- ----- ----- ----- Second Wrapper Styles ----- ----- ----- ----- */



/* ===== ===== ===== ===== Second Breakpoint Style (960px) ===== ===== ===== ===== */

/* ----- ----- ----- ----- First Wrapper Styles ----- ----- ----- ----- */
@media (max-width: 1200px) {
  .first_wrapper{
    display: grid;
    width:90%;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(300px, auto);

    row-gap: 24px;
    column-gap:24px;

    margin:12px 0;

    .map_wrapper{
      grid-row: 1 / 3;
    }

    .widget_1_container{
      grid-column:2/3;
      grid-row: 1;
    }
    .widget_2_container{
      grid-column:2/3;
      grid-row: 2;
    }
    .widget_3_container{
      grid-column:1/2;
      grid-row: 3;
    }
    .widget_4_container{
      grid-column:2/3;
      grid-row: 3;
    }
  }
}
/* ----- ----- ----- ----- Second Wrapper Styles ----- ----- ----- ----- */
@media (max-width: 1200px){
  .second_wrapper{
    display: grid;
    width:90%;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(300px, auto);

    row-gap: 24px;
    column-gap:24px;

    margin:12px 0;

    .graph_01_wrapper{
      grid-column: 1/2;
      grid-row: 1;
    }
    .graph_02_wrapper{
      grid-column: 2/3;
      grid-row: 1;
    }
    .graph_03_wrapper{
      grid-column: 1/2;
      grid-row: 2;
    }
    .graph_1_wrapper{
      grid-column: 2/3;
      grid-row: 2;
    }
    .graph_2_wrapper{
      grid-column: 1/2;
      grid-row: 3;
    }
    .graph_3_wrapper{
      grid-column: 2/3;
      grid-row: 3;
    }
    .graph_4_wrapper{
      grid-column: 1/2;
      grid-row: 4;
    }
    .graph_5_wrapper{
      grid-column: 2/3;
      grid-row: 4;
    }
    .graph_6_wrapper{
      grid-column: 1/3;
      grid-row: 5;
    }

  }
}


/* ===== ===== ===== ===== Third Breakpoint Style (720px) ===== ===== ===== ===== */

/* ----- ----- ----- ----- First Wrapper Styles ----- ----- ----- ----- */
@media (max-width: 900px) {
  .first_wrapper{
    display: block;
    width:90%;

    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin: 0;
    //row-gap: 12px;
    //column-gap:12px;

    .map_wrapper{
      height:300px;
      margin: 24px 0;

      z-index:-1001;


    }

    .widget_1_container{
      height:300px;
      margin: 12px 0;
    }
    .widget_2_container{
      height:300px;
      margin: 24px 0;
    }
    .widget_3_container{
      height:300px;
      margin: 24px 0;
    }
    .widget_4_container{
      height:300px;
      margin: 12px 0;
    }
  }
}
/* ----- ----- ----- ----- Second Wrapper Styles ----- ----- ----- ----- */
@media (max-width: 900px) {
  .second_wrapper{
    display: block;
    width:90%;

    margin: 0;

    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    //row-gap: 12px;
    //column-gap:12px;

    .graph_01_wrapper{
      height:300px;
      margin: 24px 0;
    }
    .graph_02_wrapper{
      height:300px;
      margin: 24px 0;
    }
    .graph_03_wrapper{
      height:300px;
      margin: 24px 0;
    }
    .graph_1_wrapper{
      height:300px;
      margin: 24px 0;
    }
    .graph_2_wrapper{
      height:300px;
      margin: 24px 0;
    }
    .graph_3_wrapper{
      height:300px;
      margin: 24px 0;
    }
    .graph_4_wrapper{
      height:300px;
      margin: 24px 0;
    }
    .graph_5_wrapper{
      height:300px;
      margin: 24px 0;
    }
    .graph_6_wrapper{
      height:300px;
      margin: 12px 0;
    }
  }
}