// Sass & CSS Variables

/* CUSTOM PROPERTY / CSS Variables */
//:root {
//  // COLORS
//  --color-primary: #56AB91;
//  --color-primary-light: #D6EFE4;
//  --color-primary-dark: #036666;
//
//  --color-primary-rgba: 86, 171, 145;
//  --color-primary-light-rgba: 0, 0, 0;
//  --color-primary-dark-rgba: 3, 102, 102;
//
//  --color-secondary-light: #4b74d3;
//  --color-secondary-dark: #1f3c80;
//
//  --color-warning-light: #d9ba65;
//  --color-warning-dark: #af8b2b;
//
//  --color-error-light: #d34b74;
//  --color-error-dark: #801f3c;
//
//  --color-white: #f4f4f4;
//  --color-black: #333333;
//
//  --color-white-rgba: 255, 255, 255;
//  --color-black-rgba: 0, 0, 0;
//
//  // FONTS
//  --font-size-default: 1.6rem;
//
//  // BOX-SHADOWS
//  // X-Y-BLUR
//  --box-shadow-dark: 0 2rem 6rem rgba(0, 0, 0, .3);
//  --box-shadow-light: 0 2rem 5rem rgba(var(--color-black-rgba), .06);
//
//  // BORDERS
//  --line: .1rem solid var(--color-black);
//}

/* SASS VARIABLES */
// COLORS

 $color-primary: #56AB91;
 $color-primary-light: #D6EFE4;
 $color-primary-dark: #036666;

 $color-primary-rgba: 86, 171, 145;
 $color-primary-light-rgba: 0, 0, 0;
 $color-primary-dark-rgba: 3, 102, 102;

 $color-secondary-light: #4b74d3;
 $color-secondary-dark: #1f3c80;

 $color-warning-light: #d9ba65;
 $color-warning-dark: #af8b2b;

 $color-error-light: #d34b74;
 $color-error-dark: #801f3c;

 $color-white: #f4f4f4;
 $color-black: #333333;


// Padding and Margin

  $default-padding: 12px;
  $default-padding-sm: 10px;
  $default-padding-lg: 16px;

  $default-margin: 12px;
  $default-margin-sm: 10px;
  $default-margin-lg: 16px;

  $default-border-radius: 24px;
  $default-border-radius-sm: 12px;



// MEDIA QUERIES
$bp-1800: 112.5em;
$bp-1200: 75em;
$bp-960: 56.25em;
$bp-720: 43.75em;
$bp-480: 37.5em;
$bp-000: 0em;