@import '../abstracts/mixins';
@import '../base/typography';

.dropdown_container{
  width:200px;

  .selected_area{
    @include flex-row_jc_center_center;
    @include raleway-1rem-regular;
    @include default-border-radius;

    margin:0 auto;

    width:inherit;
    height: 2.5rem;

    background-color: rgb(0 0 0 / 0.5);
    color:#ffffff;

    cursor:pointer;

    .selected_table{
      @include raleway-1rem-regular;

      width:90%;
      text-align: center;

      .selected_row{
        @include flex_row_jc_evenly_center;
      }
    }
  }


  .dropdown_item {
    @include flex_row_jc_center_center;
    @include raleway-1rem-regular;

    width: calc(0.8 * 200px);
    z-index: 5;

    background-color: rgb(0 0 0 / 50%);
    color: rgb(255 255 255 / 100%);

    transition: 20ms ease all;
    height: 2.5rem;

    cursor:pointer;
  }

  .dropdown_item:hover{
    background-color: rgb(255 255 255 / 50%);
  }



  .drop_visible{
    @include flex_col_jc_center_center;
    width:inherit;

    position: absolute;
    z-index: 5;

    overflow-x: hidden;
    overflow-y: scroll;
  }


  .drop_hidden {
    display: none;
    position: absolute;
  }

  .menu_visible{
    width:80%;
  }

  .menu_hidden{
    display:none;
  }

}