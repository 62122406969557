@import "../abstracts/mixins";
@import "../abstracts/variables";
@import "../base/typography";

.mst_page_main_container{
  @include flex_col_jc_center_center;

  text-align: center;
  overflow-y: scroll;

  .sp_main_title{
    @include raleway-15rem-bold;

    color:$color-white;

    .sp_title_caption{
      @include lato-1rem-regular;
      margin-bottom:2rem;
    }
  }

  .param_selection_field{
    @include flex_row_jc_spaceb_center;

    width:80%;

    .input_tanggal{
      @include flex_row_jc_evenly_center;
      @include raleway-1rem-regular;
      color:#ffffff;

      margin: 0 1rem 0 1rem;

      .input_label{
        margin: 0 1rem 0 1rem
      }

      .date_input_field{

        @include flex-row_jc_center_center;
        @include raleway-1rem-regular;
        @include border-radius-default;
        padding-left: 12px;

        display: block;
        outline: none;
        border: none;
        width: 200px;
        height: 2.5rem;

        background-color: rgb(0 0 0 / 0.5);
        color:#ffffff;

      }
    }

    .submit_param_button{

      @include raleway-1rem-regular;

      border: none;
      outline: none;
      margin: 1rem 0;
      height: 2.5rem;
      background-color: $color-white;
      border-radius: 6px;
      width: 100px;


      cursor: pointer;
    }
  }

  .soil_radar_container{
    height:fit-content;
    width: 80%;

    margin: 2rem 0;
  }

  .mst_wrapper{
    width:90%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(400px, auto);

    row-gap: 24px;
    column-gap:24px;

    margin:12px 0;

    .mst_info_container{

      @include default-box-shadow;
      grid-column:1/2;
      grid-row: 1;
    }
    .mst_graph_container{

      @include default-box-shadow;
      grid-column:2/3;
      grid-row: 1;
    }
  }
}