@import '../abstracts/mixins';
@import '../abstracts/variables';
@import '../base/typography';



.loading_container{
  @include default-box-shadow;
  @include flex_col_jc_center_center;

  height:300px;
  background-color: #333333;
  color:$color-white;
}

.cycle_panel_container{
  @include default-box-shadow;
  @include flex_col_jc_spaceb_center;
  padding:1rem 0;

  height:300px;
  width:100%;

  background-color: #333333;
  .panel_title{
    @include flex_col_jc_center_center;
    @include lato-1rem-regular;
    color:rgb(255 255 255);

    .panel_caption{
      @include lato-08rem-regular;
    }
  }
  .line_chart_container{
    @include flex_col_jc_center_center;

    height: 200px;
    width:90%;
  }
}
