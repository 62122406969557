@import '../abstracts/mixins';
@import '../abstracts/variables';
@import '../base/typography';

.main_container{

  @include flex_col_jc_center_center;

  text-align: center;
  overflow-y: scroll;

  .agroekonomi_main_title{
    @include raleway-15rem-bold;

    color:$color-white;

    .agroekonomi_title_caption{
      @include lato-1rem-regular;
      margin-bottom:2rem;
    }
  }

  .national_mst_title{
    @include lato-1rem-regular;
    margin-top:2rem;
    color:$color-white;
  }

  .national_mst_container{
    @include default-box-shadow;
    height: 400px;
    width: 1000px;

    margin: 2rem 0;
  }


  .download_button_container{
    @include flex_row_jc_center_center;
    .download_button {
      @include flex_col_jc_center_center;
      @include raleway-1rem-bold;
      /* Rectangle 2 */
      width: fit-content;
      height: 40px;

      background: #D6EFE4;
      border-radius: 12px;
      color: #036666;

      padding: 0 1rem;
      margin: 2rem 1rem;

      cursor: pointer;
    }
  }
}

@media (max-width: 1000px) {

  .national_mst_container {
    @include default-box-shadow;
    transform: scale(0.80); // 75% of original size
  }

}

@media (max-width: 900px) {
  .national_mst_container {
    @include default-box-shadow;
    transform: scale(0.60); // 75% of original size
  }
}

@media (max-width: 720px) {
  .national_mst_container {
    @include default-box-shadow;
    transform: scale(0.50); // 75% of original size
  }
}

@media (max-width: 480px) {
  .main_container{
    @include absolute-center;
    @include flex_col_jc_center_center;

    text-align: center;
    overflow-y: scroll;

    .national_mst_container {
      @include default-box-shadow;
      transform: scale(0.40); // 75% of original size
      margin: 0;
    }
  }
}
