
@mixin lato-08rem-regular{
  font-family: Lato, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
}

@mixin lato-08rem-bold{
  font-family: Lato, sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
}

@mixin raleway-08rem-regular{
  font-family: Raleway, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
}

@mixin raleway-08rem-bold{
  font-family: Raleway, sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
}
@mixin lato-1rem-regular{
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

@mixin lato-1rem-bold{
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

@mixin raleway-1rem-regular{
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

@mixin raleway-1rem-bold{
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

@mixin lato-15rem-regular{
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}

@mixin lato-15rem-bold{
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

@mixin raleway-15rem-regular{
  font-family: Raleway, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}

@mixin raleway-15rem-bold{
  font-family: Raleway, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

@mixin lato-2rem-regular{
  font-family: Lato, sans-serif;
  font-size: 2rem;
  font-weight: 400;
}

@mixin lato-2rem-bold{
  font-family: Lato, sans-serif;
  font-size: 2rem;
  font-weight: 600;
}

@mixin raleway-2rem-regular{
  font-family: Raleway, sans-serif;
  font-size: 2rem;
  font-weight: 400;
}

@mixin raleway-2rem-bold{
  font-family: Raleway, sans-serif;
  font-size: 2rem;
  font-weight: 600;
}

@mixin lato-3rem-regular{
  font-family: Lato, sans-serif;
  font-size: 3rem;
  font-weight: 400;
}

@mixin lato-3rem-bold{
  font-family: Lato, sans-serif;
  font-size: 3rem;
  font-weight: 600;
}

@mixin raleway-3rem-regular{
  font-family: Raleway, sans-serif;
  font-size: 3rem;
  font-weight: 400;
}

@mixin raleway-3rem-bold{
  font-family: Raleway, sans-serif;
  font-size: 3rem;
  font-weight: 600;
}

@mixin lato-4rem-regular{
  font-family: Lato, sans-serif;
  font-size: 4rem;
  font-weight: 400;
}

@mixin lato-4rem-bold{
  font-family: Lato, sans-serif;
  font-size: 4rem;
  font-weight: 600;
}

@mixin raleway-4rem-regular{
  font-family: Raleway, sans-serif;
  font-size: 4rem;
  font-weight: 400;
}

@mixin raleway-4rem-bold{
  font-family: Raleway, sans-serif;
  font-size: 4rem;
  font-weight: 600;
}






