// Base

/* universal selector */
/* all elements + all pseudo elements */

@import "../abstracts/mixins";

*,
*::after,
*::before {
  /* remove all the default margins and paddings */
  margin: 0;
  padding: 0;
  /* inherit from html and apply everywhere  */
  box-sizing: inherit;
  line-height: 1.5;
}

html {
  box-sizing: border-box;
  // this defines what 1rem is
  /* root font size */
  /* 1rem = 10px */
  /* font-size: 10px; */
  /* use % better for users accessibility */
  /* 10px we want this/16px default = 0,625 * 100 = 62.5% */
  font-size: 62.5% ;
}

/* inheritance inside body */
body {
  min-height: 100vh;
}

a:link,
a:visited,
button,
.btn {
  &:focus,
  &:focus::before,
  &:focus::after {
    @include outline;
  }
}