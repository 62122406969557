@import "../abstracts/mixins";
@import "../abstracts/variables";
@import "../base/typography";

.loading_container{
  @include default-box-shadow;
  @include flex_col_jc_center_center;

  height:300px;
  background-color: #333333;
  color:$color-white;
}

.soil_panel_container{
  @include flex_col_jc_spaceb_center;
  @include default-box-shadow;

  height:300px;

  background-color: $color-black;

  .soil_panel_title {
    @include flex_col_jc_center_center;
    @include lato-1rem-regular;

    color: rgb(255 255 255 / 80%);
    text-align: left;

    margin-top: 3rem;
  }
  .soil_panel{
    @include flex_row_jc_center_center;
    width:90%;

    background-color: $color-black;

    .soil_panel_icon{
      @include flex_row_jc_center_center;
      flex:1;
      height:120px;
    }

    .soil_panel_details{
      @include flex_col_jc_center_center;
      flex:2;

      .mineral_container{
        @include flex_row_jc_spaceb_center;
        width:80%;
        .mineral_table{
          .mineral_table_cell{
            @include flex_col_jc_center_center;
            height:fit-content;
          }

          .text_info{
            @include raleway-1rem-regular;
            color: $color-white;
          }

          .mineral_value{
            @include lato-4rem-bold;
          }

          .nitrogen_details{
            color : $color-secondary-light;
          }

          .phosphorous_details{
            color : $color-warning-light;
          }

          .potassium_details{
            color : $color-primary;
          }
        }
      }
    }
  }

  .extra_information{
    @include flex_row_jc_center_center;

    width:80%;

    .extra_information_table{
      @include raleway-1rem-regular;
      width:100%;

      margin-bottom: 2rem;

      .table_title{
        @include raleway-1rem-bold;
        text-align: start;
        color:$color-white;
      }

      .table_value{
        @include lato-1rem-bold;
        text-align: end;
        color:$color-white;
      }
    }
  }
}