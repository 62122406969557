@import "../abstracts/mixins";
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;500;600;700&family=Lato:wght@300;400;700&family=Raleway:wght@200;300;400;500;700&display=swap');


.navbar_container{
  @include flex_row_jc_spaceb_center;

  background-color:#DDDDDD;
  width: 100%;
  height: 55px;

  //border-radius:12px;
  /* Rectangle 1 */

  position: relative;
  //top: 24px;
  //left:2.5%;
  //right:2.5%;
  z-index: 2000;
}

.navbar_logo_container{
  margin: 0 16px;

  .VscMenu{
    color: #036666;
  }
}

.navbar_login_button{
  @include flex_col_jc_center_center;
  /* Rectangle 2 */
  width: 100px;
  height: 40px;

  background: #D6EFE4;
  border-radius: 12px;

  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;

  color: #036666;
  margin: 0 16px;

  cursor: pointer;
}


//------------- ------------- ------------- ------------- //

.navbar_auth_wrapper{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  z-index:1000;

  padding: 16px 0;
  width: 200px;

  .navbar_burger_logo_container{
    padding: 0 16px;
  }
}

.navbar_auth_container{
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color:#DDDDDD;

  padding: 16px 16px;

  position: fixed;
  top: 0;
  z-index: 2000;

  width: 200px;
  height: 100%;

  border-radius:12px;
  /* Rectangle 1 */

  .navbar_auth_top{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
  }
  .navbar_auth_middle{
    display: flex;
    flex: 2;
    flex-direction: column;
    //justify-content: space-around;
    align-items: start;
    //justify-items: flex-end;
  }
  .navbar_auth_bottom{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    //justify-items: flex-end;

    width: 100%;
  }

  .navbar_auth_text_link{

    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;

    margin: 8px 0;

    color: #036666;

    //writing-mode: vertical-rl;
    cursor: pointer;
  }

  .navbar_auth_logout_button{
    @include flex_col_jc_center_center;
    /* Rectangle 2 */
    width: inherit;
    height: 40px;

    background: #D6EFE4;
    border-radius: 12px;

    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;

    color: #036666;
    //margin:16px 0;

    cursor: pointer;

   //p{
   //  rotate: 90deg;
   //}
  }
}

@media (max-width: 720px) {
  .navbar_auth_container{
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color:#DDDDDD;

    padding: 16px 16px;
    position: fixed;
    top: 0;
    width: 200px;
    height: 100%;

    z-index:2000;

    border-radius:12px;

  }
}