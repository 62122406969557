@import '../abstracts/mixins';
@import '../abstracts/variables';
@import '../base/typography';

.mst_info_main_container{
  @include flex_col_jc_center_center;
  align-items: center;
  width: 100%;
  height: 400px;
  border: 2px white solid;

  background-color: #333333;

  .mst_info_title{
    @include raleway-15rem-bold;
    margin: 1rem;
    color: rgb(255 255 255 / 100%);
  }

  .mst_info_description{
    @include raleway-1rem-bold;
    width:80%;
    margin: 1rem;
    color: rgb(255 255 255 / 100%);

    display: block;

    text-align: justify;

    .mst_info_description_title{
      margin:0.5rem 0;
    }
    .mst_info_caption{
      @include raleway-1rem-regular;

      color: rgb(255 255 255 / 100%);

      text-align: justify;
    }
  }

  .mst_node_description{
    margin:2rem 1rem;
    width:80%;

    .node_desc_row{
      @include flex_row_jc_start_center;

      .node_element{
        width:4rem;
        display:block;
        align-self: center;

        .edges_element{
          align-self: center;
          width: 2.5rem;
          height: 0.1rem;
          background-color: #c2c2c2;
          border: 3px solid #c2c2c2;
          opacity: 0.8;
        }
      }


      .circle_node{
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
      }

      .green_node{
        background: rgba(0,0,0,0.8);
        border: 4px solid $color-primary;
      }

      .red_node{
        background: rgba(0,0,0,0.8);
        border: 4px solid $color-error-light;
      }

      .white_node{
        background: rgba(0,0,0,0.8);
        border: 4px solid $color-white;
      }

      .mst_node_desc_caption{
        @include raleway-1rem-regular;

        margin: 1rem;
        color: rgb(255 255 255 / 100%);

        text-align: justify;
      }
    }
  }
}