@import '../abstracts/mixins';

.map_canvas {
  @include flex_row_jc_center_center;
  @include default-box-shadow;
  height: 500px;
  width: 1000px;
}

@media (max-width: 1000px) {

  .map_canvas {
    @include default-box-shadow;
    transform: scale(0.80); // 75% of original size
  }

}

@media (max-width: 900px) {
  .map_canvas {
    @include default-box-shadow;
    transform: scale(0.60); // 75% of original size
  }
}

@media (max-width: 720px) {
  .map_canvas {
    @include default-box-shadow;
    transform: scale(0.50); // 75% of original size
  }
}

@media (max-width: 480px) {
  .map_canvas {
    @include default-box-shadow;
    transform: scale(0.40); // 75% of original size
  }
}
