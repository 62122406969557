@import '../abstracts/mixins';
@import '../base/typography';
@import '../abstracts/variables';

.widget_container{
  @include flex_row_jc_center_center;

  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  border: 2px solid;

  .icon_container{
    @include flex_col_jc_center_center;
    height:80px;
    width:80px;
  }

  .details_container{
    margin: 0 1rem 0 0;
    text-align: start;

    .price_container{
      //@include flex_row_jc_center_center;
      text-align: start;

      h4{
        @include lato-2rem-bold;
      }
    }

    .change_container{
      @include lato-1rem-regular;
      text-align: left;
      margin: 0.1rem 0;
      color: $color-white;
    }

    .area_container{
      text-align: left;
      margin: 0.1rem 0;
      color: $color-white;
    }
  }
}