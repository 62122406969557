@import '../abstracts/mixins';
@import '../base/typography';
@import '../abstracts/variables';

.about_main_container{
  @include flex_col_jc_center_center;
  text-align: center;
  height:fit-content;

  margin:0 0 2rem 0;

  .about_title{
    @include raleway-2rem-bold;
    color: $color-white;
    margin-top: 2rem;

  }

  .about_description{
    @include raleway-1rem-regular;
    color: $color-white;
    text-align: justify;

    width:600px;

    margin:2rem 0;
  }

  .about_title_team{
    @include raleway-15rem-bold;
    color: $color-white;

    width:50%;

    margin:1rem 0;
  }

  .about_description_team{
    @include raleway-1rem-regular;
    color: $color-white;
    text-align: center;

    width:50%;

    margin:1rem 0;
  }

  .support_container{
    @include flex_row_jc_center_center;
    //width:200px;

    .support_icon{
      @include flex_row_jc_center_center;
      margin:0.5rem 1.5rem;
      background-color: $color-white;
      opacity: 0.8;
    }
    .bfitech_icon{
      cursor:pointer;
    }
  }
}

@media (max-width: 700px) {
  .about_main_container{
    .about_title{
      @include raleway-2rem-bold;
      color: $color-white;
      margin-top: 2rem;
    }

    .about_description {
      @include raleway-1rem-regular;
      color: $color-white;
      text-align: justify;

      width: 300px;
    }
  }
}