@import '../abstracts/mixins';
@import '../base/typography';

.test_weather_container{
  background-color: #d34b74;
}
.time_weather_container{
  @include flex_col_jc_center_center;
  text-align: center;
  width: fit-content;
  border-radius: 1rem;

  .weather_title_container{
    @include flex_col_jc_center_center;
    color:white;

    .widget_title{
      @include lato-1rem-regular;
      color: rgb(255 255 255 / 100%);
    }
    .widget_day_title{
      color: rgb(255 255 255 / 80%);
      font-family: Lato, sans-serif;
      font-size: 0.8rem;
      font-weight: 400;
    }
  }

  .weather_icon_caption_container{
    .weather_icon{
      height:80px;
      width:80px;
    }
    .weather_caption {
      @include lato-1rem-regular;
      margin-bottom: 0.2rem;
      color: rgb(255 255 255 / 100%);
    }
  }

  .weather_info_container{
    .weather_info_background{
      @include flex_row_jc_center_center;
      width: fit-content;
      margin-top: 0.3rem;
      padding: 0 0.2rem;
      border-radius: 1.6rem;
      background-color: rgb(0 0 0 / 20%);
    }

    .air_info{
      @include lato-1rem-regular;
      margin: 0.1rem 0.5rem;
      color: rgb(255 255 255 / 100%);

    }
  }
}