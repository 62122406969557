@import "../abstracts/mixins";
@import "../abstracts/breakpoints";

.main_container {
  height: 100vh;
  padding: 0 2rem;
  scroll-snap-type: y mandatory;
  overflow-y: clip;
  overflow-x:clip;
  position: relative;

  section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    scroll-snap-align: start;
  }
}

// Decoration

.ornaments{
  z-index: -1;
  .ornament1{
    /* Vector */
    @include overflow-clip-xy;
    position: absolute;
    left: 0.68%;
    right: 20.68%;

    width:80vh;
    height:90vh;

    transform: rotate(11.88deg);
  }
  .ornament2{
    @include overflow-clip-xy;
    position: absolute;
    width: 80vh;
    height: 80vh;
    left: 1161px;
    top:33.7px;

    transform: rotate(-51.78deg);
  }
  .ornament3 {

    @include overflow-clip-xy;

    width: 80vh;
    height: 80vh;

    position: absolute;
    left: 55.68%;
    top: 57.39%;

    transform: matrix(0.98, -0.21, -0.21, -0.98, 0, 0);
  }
}


//Login Form

.login_form_container{
  @include absolute-center;
  @include flex_col_jc_center_center;

  width: 30vw;
  height: fit-content;

  background-color:#D9D9D9;

  border-radius: 24px;

  .login_content_container{
    @include flex_col_jc_center_center;

    .login_header {
      margin-top:3rem;
      margin-bottom: 2rem;
    h3 {
      /* Masuk */
      padding-left: 12px;

      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;

      color: $color-primary-dark;
    }

    p {
      /* untuk melanjutkan ke dashboard */
      padding-left: 12px;

      font-family: 'Raleway';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;

      color: $color-black;


    }
  }

    .login_form{
      margin: 1rem 0;
      .login_form_object{
        margin: 2rem 0;
        label {
          padding-left: 12px;
          margin-bottom: 6px;


          display:flex;
          align-items: flex-start;
          color: $color-primary-dark;

          font-family: "Raleway", sans-serif;
          font-size: 1.6rem;
        }

        .input_field{
          @include border-radius-default;
          padding-left: 12px;

          display: block;
          outline: none;
          border: none;
          width: 250px;
          height: 40px;

          background: $color-white;
          color: $color-primary-dark;

          font-family: "Raleway", sans-serif;
          font-size: 1.6rem;

        }
      }
    }


  }

  .error_msg{
    width: 250px;
    padding: 15px;
    margin: 5px 0;
    background-color: $color-error-light;
    color: white;
    border-radius: 5px;
    text-align: center;

    font-family: "Raleway", sans-serif;
    font-size: 1.2rem;
  }
  .green_btn {

    border: none;
    outline: none;
    margin: 1rem 0;
    padding: 12px 0;
    height: 40px;
    background-color: $color-primary;
    border-radius: 12px;
    width: 250px;

    font-family: "Raleway", sans-serif;
    font-size: calc(10px + 1vmin);

    cursor: pointer;
  }
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;

  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
}

@media (max-width: 1200px) {
  .login_form_container{
    width: 35vw;
  }
}

@media (max-width: 960px) {
  .login_form_container{
    width: 45vw;
  }
}

@media (max-width: 720px) {
  .login_form_container{
    width: 70vw;
  }
}

@media (prefers-color-scheme: dark) {
  .card,
  .footer {
    border-color: #222;
  }
  .code {
    background: #111;
  }
  .logo img {
    filter: invert(1);
  }
}
