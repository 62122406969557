@import '../abstracts/mixins';
@import '../abstracts/variables';
@import '../base/typography';

.footer_main_container{
  @include flex_row_jc_center_center;
  height:30px;

  position: fixed;
  bottom: 0;
  width: 100%;

  background-color: $color-primary-dark;
  .footer_content_container{
    @include lato-1rem-regular;

    .footer_content{
      color: $color-white;
    }
    .footer_content_about{
      margin: 0 1rem;
      cursor:pointer;

      color:$color-white;
    }
  }
}