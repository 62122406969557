@import '../abstracts/mixins';
@import '../abstracts/variables';
@import '../base/typography';

.loading_container{
  @include default-box-shadow;
  @include flex_col_jc_center_center;

  height:300px;
  background-color: #333333;
  color:$color-white;
}

.graph_panel_container{
  animation-duration: 1s;
  animation-fill-mode: both;
  cursor: pointer;

  .graph_panel{
    width:100%;
    height:300px;

    margin: 0 auto;

    transform-style: preserve-3d;
    transition: all 1.5s ease;

    .panel_contents{
      @include default-box-shadow;
      @include flex_col_jc_center_center;
      position:absolute;

      width:100%;
      height:inherit;

      backface-visibility: hidden;

      text-align: center;

      .graph_title{
        @include flex_col_jc_center_center;


        color: rgb(255 255 255 / 80%);
        @include lato-1rem-regular;
        text-align: left;

        margin: 1rem;

        transform: translateZ(100px) scale(0.98);
      }

      .panel_depth {
        @include flex_row_jc_center_center;

        width:90%;

        transform: translateZ(100px) scale(0.98);
        perspective: inherit;

        .chart_container{
          @include flex_col_jc_center_center;

          height: 200px;
          width:90%;
        }

        .price_widget_container{
          @include flex_col_jc_center_center;

          height: 200px;
          width:90%;
        }
      }

    }

    .panel_time_series{
      transform-style: preserve-3d;
      background: #333333;
    }

    .panel_price_widget{
      transform: rotateY(-180deg);
      transform-style: preserve-3d;
      background: #333333;
    }
  }
}

.rotated {
  transform: rotateY(180deg);
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
}

.bounce {
  animation-name: bounce;
}