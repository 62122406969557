.provincepath {
 // fill: #036666;
  stroke: #333333;
  stroke-width: .5px;
  stroke-opacity: 0.8;
  stroke-dasharray: .5;
}

.provincepath:hover {
  fill: #56AB91;
  stroke: #282c34;
  stroke-width: .5px;
  stroke-opacity: .5;
  stroke-dasharray: 2;
}

.path:hover {
  cursor: pointer;
  fill: rgb(255, 145, 0);
  fill-opacity: 0.5;
}
