@import '../abstracts/mixins';
@import '../abstracts/variables';
@import '../base/typography';
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;500;600;700&family=Lato:wght@300;400;700&family=Raleway:wght@200;300;400;500;700&display=swap');

.home_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main_container {
  height: 100vh;
  padding: 0 2rem;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x:clip;
  position: relative;

  section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    scroll-snap-align: start;
  }
}


// --------- --------- --------- --------- --------- --------- --------- --------- --------- //

.navbar_container{
  @include flex_row_jc_spaceb_center;

  background-color:#DDDDDD;
  width: 100%;
  height: 55px;

  //border-radius:12px;
  /* Rectangle 1 */

  position: relative;
  //top: 24px;
  //left:2.5%;
  //right:2.5%;
  //z-index: 2;
}

.navbar_logo_container{
  margin: 0 16px;
}

.navbar_login_button{
  @include flex_col_jc_center_center;
  /* Rectangle 2 */
  width: 100px;
  height: 40px;

  background: #D6EFE4;
  border-radius: 12px;

  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;

  color: #036666;
  margin: 0 16px;



}

// --------- --------- --------- --------- --------- --------- --------- --------- --------- //


.logo_container{
  width: 60%;
}


// Section
.content_container{
  @include flex_row_jc_evenly_center;

  .section_header{
    @include flex_row_jc_start_center;

    h1{
      font-size:36px;
      color:#f4f4f4;
    }
  }

  .image_container{
    @include flex_row_jc_evenly_center;
  }

  .text_content_container{
    flex:1;
    padding-left: 1rem;
  }
  .image_container{
    //border: 1px solid white;
    flex:1;
  }
  .image_wrapper{
    //border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0px, 0px);
  }
  .isometric1{
    /* isosemnet 1 */
  }
}

.text_grid_container{
  display: grid;
  grid-template-columns: auto auto;

  .grid_icons{
    margin: 1rem;

    .arrow_icons{
      color:#f4f4f4;
    }
  }

  .grid_content{
    text-align: Left;
    /* Membuat model, analisis spasial dan penginderaan jarak jauh untuk mendukung perancangan, pengembangan dan implementasi sistem di lapangan */

    width: 80%;
    height: 30%;


    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;

    color: #FFFFFF;

    margin:0 0 1rem 0;


  }
}

// Decoration
.circle_ornament_1{
  /* Ellipse 1 */
  box-sizing: border-box;

  position: absolute;
  width: 1000px;
  height: 1000px;
  left: 939px;
  top: -228px;

  background: rgba(3, 102, 102, 0.42);
  border-radius: 50%;
  filter: blur(50px);

  z-index: -1;
}
.circle_ornament_2{
  /* Ellipse 2 */

  position: absolute;
  width: 600px;
  height: 600px;
  left: -118px;
  top: 494px;

  background: rgba(86, 171, 145, 0.5);
  border-radius: 50%;
  filter: blur(70px);

  z-index: -1;
}
.circle_ornament_3{
  /* Ellipse 3 */

  position: absolute;
  width: 484px;
  height: 452px;
  left: 1143px;
  top: calc( 200vh - 184px);

  background: #85918C;
  border-radius: 50%;
  filter: blur(50px);

  z-index: -1;
}
.circle_ornament_4{
  /* Ellipse 5 */

  position: absolute;
  width: 600px;
  height: 600px;
  left: 218px;
  top: calc(102px + 200vh);

  background: #1F4949;
  border-radius: 50%;
  filter: blur(50px);

  z-index: -1;

}


.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;

  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
}


@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }


  .content_container{
    @include flex_col_jc_evenly_center;

    .section_header{
      @include flex_row_jc_center_center;

      h1{
        font-size:24px;
        padding-left: 2.5rem;
        color:#f4f4f4;
      }
    }

    .image_container{
      @include flex_row_jc_evenly_center;
    }

    .text_content_container{
      flex:1;
      padding-left: 1rem;

      .grid_content{
        font-size:12px;
        line-height: 1.5;
        //padding-left: 2.5rem;
        color:#f4f4f4;
      }
    }
    .image_container{
      //border: 1px solid white;
      flex:1;
    }
    .image_wrapper{
      //border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(0px, 0px);
    }
    .isometric1{
      /* isosemnet 1 */
      width: 250px;
      height: 250px;

    }
  }
}

@media (prefers-color-scheme: dark) {
  .card,
  .footer {
    border-color: #222;
  }
  .code {
    background: #111;
  }
  .logo img {
    filter: invert(1);
  }
}
