@import "../abstracts/mixins";
@import "../abstracts/variables";
@import "../base/typography";

.soil_profile_main_container{
  @include flex_col_jc_center_center;

  text-align: center;
  overflow-y: scroll;

  .sp_main_title{
    @include raleway-15rem-bold;

    color:$color-white;

    .sp_title_caption{
      @include lato-1rem-regular;
      margin-bottom:2rem;
    }
  }

  .soil_radar_container{
    height:fit-content;
    width: 80%;

    margin: 2rem 0;
  }
}