@import "../abstracts/mixins";
@import "../abstracts/variables";
@import "../base/typography";

.loading_container{
  @include default-box-shadow;
  @include flex_col_jc_center_center;

  color:$color-white;
  padding:2rem 0;
  margin: 0 0 3rem 0;

  height:500px;
  width:100%;

  background-color: #333333;
}
.radar_main_container{
  .radar_chart_container{
    @include default-box-shadow;
    @include flex_col_jc_spaceb_center;
    padding:2rem 0;
    margin: 0 0 3rem 0;

    height:500px;
    width:100%;

    background-color: #333333;
  }

  .selection_container{

    .selection_title{
      @include raleway-1rem-bold;
      color:$color-white;

      margin:2rem 0;
    }

    .dropdown_wrapper{
      @include flex_row_jc_evenly_center;

      .dropdown_menu_container{
        .selection_caption{
          @include raleway-1rem-regular;
          color:$color-white;

          margin:1rem 0;
        }
      }
    }
  }
}
